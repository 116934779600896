import AboutImage from '../../assets/500x_Black.png'
import CV from '../../assets/Pitch.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about">
      <div className="container about__container" data-aos="fade-in">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="About Image" />
          </div>
        </div>
        <div className="about__right">
          <h2>About Us</h2>
          <div className="about__cards">
            {
              data.map(item => (
                <Card key={item.id} className="about__card">
                  <span className='about__card-icon'>{item.icon}</span>
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))
            }
          </div>
          <p>
          Creating innovative projects and video games is our passion. 
          The team consists of professionals with years in the industry who continue to improve, 
          motivated to do more and better!
          </p>
          <p>
          Hi, we are an Italian software house, 
          we deal with 3D software development and videogames. 
          Our priority is to manage and realise our customers' projects and, in parallel, 
          in-house projects. By giving us all the details of your project or idea, 
          we will help you realise and implement it, 
          working with precision and professionalism. 
          </p>
          <a href={CV} download className='btn primary'>Download Pitch<HiDownload/></a>
        </div>
      </div>
    </section>
  )
}

export default About