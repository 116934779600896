import Modal from "../components/Modal"
import BackgroundColor from "./BackgroundColor"
import { backgroundColors } from "./data"
import './theme.css'


const Theme = () => {
  return (
    <Modal className="theme__modal">
        <h3>Change Theme</h3>
        <div className="theme__background-wrapper">
            <div className="theme__background-colors">
                {
                    backgroundColors.map(bColor => <BackgroundColor key={bColor.className} className={bColor.className}/>)
                }
            </div>
        </div>
    </Modal>
  )
}

export default Theme