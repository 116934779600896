import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'

const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: 'Team with 5+ Years Working'},
    {id: 2, icon: <TbBooks/>, title: 'Projects', desc: 'Innovative and challenging projects'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Clients', desc: 'More happy clients'}
]

export default data;