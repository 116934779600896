import { useEffect } from 'react'
import HeaderImage from '../../assets/IMORI-LOGO-GRANDE.png'
import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header id = "header">
        <div className="container header__container">
          <div className="header__profile" data-aos="fade-in">
            <img src={HeaderImage} alt="Header Portait" />
          </div>
          <p data-aos="fade-up">
          You are one click away from creating your own 3D app, 
          send us your design details for modern, mobile responsive, 
          and highly responsive software!
          </p>
          <div className="header__cta" data-aos="fade-up">
            <a href="#contact" className='btn primary'>Let's Talk</a>
            {/*<a href="#portfolio" className='btn light'>Our Works</a>*/}
          </div>
          <div className="header__socials">
            {
              data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer" >{item.icon}</a>)
            }
          </div>
        </div>
    </header>
  )
}

export default Header