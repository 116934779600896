import { FaUnity } from "react-icons/fa6"
import { SiBlender } from "react-icons/si"
import { FaMeta } from "react-icons/fa6"
import { TbAugmentedReality2 } from "react-icons/tb"

const data = [
    {id: 1, icon: <FaUnity/>, title: 'Develops', desc: "Our software is innovative and intuitive. We use industry-standard rules to produce a functional and good product."},
    {id: 2, icon: <SiBlender/>, title: '3D Modelling', desc: "We create 3D assets for our products, optimising polygonal density and details according to project requirements."},
    {id: 3, icon: <FaMeta/>, title: 'Virtual Reality', desc: "Experts in VR apps, we can make products designed and created to work on the latest generation of VR helmets."},
    {id: 4, icon: <TbAugmentedReality2/>, title: 'Augmented Reality', desc: "We use augmented reality to manage virtual information visible on the real plane."}
]

export default data