import Logo from '../../assets/500x_Black.png'
import data from './data'
import { GoBook } from "react-icons/go";
import {useModalContext} from '../../context/modal-context'
import './navbar.css'

const Navbar = () => {

  return (
    <nav>
      <div className="container nav__container">
        <a href="index.html" className='nav__logo'>
          <img src={Logo} alt="Logo" />
        </a>
        <ul className='nav__menu'>
          {
            data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
          }
        </ul>
        <button id='theme__icon'onClick={() => window.location.href = "https://imoristudio.com/blog/"}>{<GoBook />}</button>
      </div>
    </nav>
  )
}

export default Navbar